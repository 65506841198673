//
// Aside
//

// General mode(all devices)
.aside {
	display: flex;
	flex-direction: column;
	background-color: var(--kt-aside-bg-color);
	padding: 0;

	// Aside menu
	.aside-menu {
		display: flex;
		align-items: center;
		width: 100%;

		.menu {			
			// Menu item
			> .menu-item {
				> .menu-link {
					.menu-title {
						font-size: 1rem;
					}
				}
			
				&.menu-accordion {
					&.show,
					&.showing {
						> .menu-link {
							> .menu-icon {
								.svg-icon {
									@include menu-link-arrow-rotate(90deg, -90deg);
								}						
							}
						}
					}

					&.hiding {
						> .menu-link {
							> .menu-icon {
								.svg-icon {
									@include menu-link-arrow-rotate(0, 0);
								}						
							}
						}
					}	
				}			
			}

			// Link colors
			.menu-item {
				@include menu-link-default-state( $title-color: var(--kt-gray-700), $icon-color: var(--kt-gray-600), $bullet-color: var(--kt-gray-400), $arrow-color: var(--kt-gray-400), $bg-color: transparent );
				@include menu-link-hover-state( $title-color: var(--kt-gray-900), $icon-color: null, $bullet-color: null, $arrow-color: null, $bg-color: transparent );
				@include menu-link-here-state( $title-color: var(--kt-gray-900), $icon-color: null, $bullet-color: null, $arrow-color: null, $bg-color: transparent );
				@include menu-link-show-state( $title-color: var(--kt-gray-900), $icon-color: var(--kt-gray-900), $bullet-color: var(--kt-gray-900), $arrow-color: null, $bg-color: transparent );
				@include menu-link-active-state( $title-color: var(--kt-gray-900), $icon-color: var(--kt-gray-900), $bullet-color: var(--kt-gray-900), $arrow-color: null, $bg-color: transparent );

				> .menu-link.active {
					.menu-title {
						font-weight: $font-weight-bold;
					}
				}
			}
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	.aside {
		position: fixed;
		top: get($content-spacing, desktop);
		bottom: get($content-spacing, desktop);
		left: get($content-spacing, desktop);
		z-index: get($aside-config, z-index);
		overflow: hidden;
		width: get($aside-config, width);
		@include border-radius(get($aside-config, border-radius));

		// Logo
		.aside-logo {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	.aside {
		display: none;

		// Logo
		.aside-logo {
			display: none;
		}
	}
}
